/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import Img, { FluidObject } from 'gatsby-image'
import { OVERLAY_COLOR, TOverlay } from '../pageBuilder/sections/CardSection/styles'

interface Props {
  url: FluidObject
  overlay?: TOverlay
  children?: React.ReactNode
}

const Banner = ({ url, children, overlay }: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        ...(overlay && {
          '::before': {
            content: '""',
            background: OVERLAY_COLOR[overlay],
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          },
        }),
      }}
      className="banner"
    >
      <Img
        fluid={{ ...url, aspectRatio: 1490 / 840 }}
        sx={{
          mb: 'md1',
          zIndex: 0,
        }}
      />
      {children}
    </Box>
  )
}

export default Banner
